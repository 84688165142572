/** @format */
import FeatureAccess from './FeatureAccess'
import Opportunity from './Opportunity'
import SelectOption from './interface/SelectOption'

export default [
  //  Insights
  new FeatureAccess('insights', 'view'),

  //  AdOps
  new FeatureAccess('adops', 'view'),

  //  Companies
  new FeatureAccess('company', 'view'),
  new FeatureAccess('company', 'edit'),

  // Users
  new FeatureAccess('user', 'view'),
  new FeatureAccess('user', 'edit'),
  new FeatureAccess('user', 'delete'),

  // Profiles
  new FeatureAccess('profile', 'view'),
  new FeatureAccess('profile', 'edit'),
  new FeatureAccess('profile', 'delete'),

  //  Media Package
  new FeatureAccess('media_package', 'view'),
  new FeatureAccess('media_package', 'edit'),
  new FeatureAccess('media_package', 'delete'),

  //  Media Plan
  new FeatureAccess('media_plan', 'view'),
  new FeatureAccess('media_plan', 'review'),
  new FeatureAccess('media_plan', 'edit'),
  new FeatureAccess('media_plan', 'delete'),
  new FeatureAccess('media_plan', 'batch'),

  // Zipcode Lookup
  new FeatureAccess('zipcode_lookup', 'view'),

  // Rate Card Lookup
  new FeatureAccess('rate_card', 'view'),

  //  Inventory
  new FeatureAccess('inventory', 'view'),
  new FeatureAccess('inventory', 'edit'),
  new FeatureAccess('inventory', 'batch'),

  //  Scheduler
  new FeatureAccess('scheduler', 'view'),
  new FeatureAccess('scheduler', 'edit'),
  new FeatureAccess('scheduler', 'delete'),

  //  Creative
  new FeatureAccess('creative', 'view'),
  new FeatureAccess('creative', 'edit'),

  //  Forecast
  new FeatureAccess('forecast', 'view'),
  new FeatureAccess('forecast', 'edit'),

  //  Reports
  new FeatureAccess('report', 'view'),
  new FeatureAccess('report', 'edit'),

  // Report Views
  new FeatureAccess('report_view', 'view'),
  new FeatureAccess('report_view', 'edit'),
  new FeatureAccess('report_view', 'delete'),

  //  Agreement
  new FeatureAccess('agreement', 'view'),
  new FeatureAccess('agreement', 'edit'),
  new FeatureAccess('agreement', 'delete'),

  //  Terms
  new FeatureAccess('term', 'view'),
  new FeatureAccess('term', 'edit'),
  new FeatureAccess('term', 'delete'),

  //  Reconciliation
  new FeatureAccess('reconciliation', 'view'),
  new FeatureAccess('reconciliation', 'edit'),
  new FeatureAccess('reconciliation', 'delete'),

  //  Dynamic Rate
  new FeatureAccess('dynamic_rate', 'view'),
  new FeatureAccess('dynamic_rate', 'edit'),
  new FeatureAccess('dynamic_rate', 'delete'),

  //  Invoices
  new FeatureAccess('invoice', 'view'),
  new FeatureAccess('invoice', 'edit'),
  new FeatureAccess('invoice', 'delete'),
  new FeatureAccess('invoice', 'review'),

  //  Payment
  new FeatureAccess('payment', 'view'),
  new FeatureAccess('payment', 'edit'),
  new FeatureAccess('payment', 'delete'),

  //  Expense
  new FeatureAccess('expense', 'view'),
  new FeatureAccess('expense', 'edit'),
  new FeatureAccess('expense', 'delete'),

  //  Model File
  new FeatureAccess('model_file', 'view'),
  new FeatureAccess('model_file', 'edit'),
  new FeatureAccess('model_file', 'delete'),

  //  Media Ocean
  new FeatureAccess('task', 'view'),
  new FeatureAccess('task', 'edit'),
  new FeatureAccess('task', 'delete'),

  //  Finances
  new FeatureAccess('finance', 'view'),

  //  Audits
  new FeatureAccess('audit', 'view'),

  //  Credit Memo
  new FeatureAccess('memo', 'view'),
  new FeatureAccess('memo', 'edit'),
  new FeatureAccess('memo', 'delete'),

  //  Journal
  new FeatureAccess('journal', 'view'),
  new FeatureAccess('journal', 'edit'),
  new FeatureAccess('journal', 'delete'),

  //  Commissions
  new FeatureAccess('commission', 'view'),
  new FeatureAccess('commission', 'edit'),
  new FeatureAccess('commission', 'delete'),

  //  AdServer
  new FeatureAccess('adserver', 'view'),
  new FeatureAccess('adserver', 'edit'),
  new FeatureAccess('adserver', 'delete'),
  new FeatureAccess('adserver', 'monitor'),
  new FeatureAccess('adserver', 'options', false, []),

  //  System Config
  new FeatureAccess('system_config', 'view'),
  new FeatureAccess('system_config', 'edit'),
  // new FeatureAccess('system_config', 'delete'),

  //  System Tools
  new FeatureAccess('system_tools', 'view'),

  // Max Avails
  new FeatureAccess('max_avails_plan', 'view'),
  new FeatureAccess('max_avails_plan', 'edit'),
  new FeatureAccess('max_avails_plan', 'delete'),

  // Opportunity
  new FeatureAccess('opportunity', 'view'),
  new FeatureAccess('opportunity', 'edit'),
  new FeatureAccess('opportunity', 'delete'),
  new FeatureAccess('opportunity', 'options', false, []),

  // FAQ
  new FeatureAccess('faq', 'edit'),

  // Management Dashboard
  new FeatureAccess('management_dashboard', 'view'),
  new FeatureAccess('management_dashboard', 'options', false, []),

  // FinancialPlan
  new FeatureAccess('financial_plan', 'view'),
  new FeatureAccess('financial_plan', 'edit'),
  new FeatureAccess('financial_plan', 'delete'),

  // Billboard
  new FeatureAccess('billboard', 'view'),
  new FeatureAccess('billboard', 'edit'),
  new FeatureAccess('billboard', 'delete'),
]

export const permissionOptions = {
  management_dashboard: [
    new SelectOption('Overview Dashboard', 'overview_dashboard'),
    new SelectOption('Leadership Dashboard', 'leadership'),
    new SelectOption('Management Dashboard', 'management_dashboard'),
    new SelectOption('Sales Dashboard', 'sales_dashboard'),
    new SelectOption('Cross Region Access', 'cross_region'),
    new SelectOption('MLB Dashboard', 'mlb_dashboard'),
    new SelectOption('DirecTV Dashboard', 'directv_dashboard'),
    new SelectOption('Hamilton Dashboard', 'hamilton_dashboard'),
    new SelectOption('Weekly Sales', 'weekly_sales'),
    new SelectOption('Sales Activity', 'sales_activities'),
    new SelectOption('Active International', 'international_term'),
    new SelectOption('Marketing Workload', 'marketing_workload'),
    new SelectOption('SSL Order', 'ssl_order_analysis'),
  ],
  adserver: [new SelectOption('Delivery Boost', 'delivery_boost')],
  opportunity: [new SelectOption('Force New Business', 'force_new_business')],
}
